import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { Notification } from "../../../components/GenericActions";
import twilioCallService from "./twilioCall.service";

const initialState = {
  getTwilioToken: null,

  isError: false,
  isSuccess: false,
  isLoading: false,
  statusMessage: "",
};

export const twilioTokenAction = createAsyncThunk(
  "twilio/getToken",
  async (startupClient, thunkAPI) => {
    try {
      const response = await twilioCallService.twilioCall();

      if (response.succeeded === true) {
        if (startupClient) {
          startupClient(response);
        }
      }
      // Notification(response.message, response.succeeded);
      return response;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      if (error?.response?.status != 401) {
        Notification(message, false);
      }
      return thunkAPI.rejectWithValue(message);
    }
  }
);

const twilioCallSlice = createSlice({
  name: "twilioSlice",
  initialState,
  reducers: {
    clearData: (state) => {
      state.isLoading = false;
      state.isSuccess = false;
      state.isError = false;
      state.statusMessage = "";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(twilioTokenAction.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(twilioTokenAction.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.getTwilioToken = action.payload;
      })
      .addCase(twilioTokenAction.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.statusMessage = action.payload;
      });
  },
});
export const { clearData } = twilioCallSlice.actions;

export default twilioCallSlice.reducer;
