import React from "react";
// import ReactDOM from "react-dom/client";
import ReactDOM from "react-dom";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { Provider } from "react-redux";
import store from "./app/store";
import "./assets/js/app";
import "./assets/js/jquerry.slimscroll";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import "react-credit-cards/es/styles-compiled.css";

// const root = ReactDOM.createRoot(document.getElementById("root"));
// root.render(
//   <React.StrictMode>
//     {/* <ToastContainer /> */}
//     <Provider store={store}>
//       <App />
//       <ToastContainer style={{ zIndex: 10000000000000 }} />
//     </Provider>
//     </React.StrictMode>
// );

ReactDOM.render(
  <React.StrictMode>
      <Provider store={store}>
        <App />
        <ToastContainer style={{ zIndex: 10000000000000 }} />
      </Provider>
  </React.StrictMode>,
  document.getElementById("root")
);


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
