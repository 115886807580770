import api from "../../../utils/api";

  const getAdminDashboardCount = async (data) => {
    const response = await api.get(`/AdminDashBoard/getRoleCount`, data);
    return response.data;
  };
  const getAdminDashboardAllApt = async (data) => {
    const response = await api.post(`/AdminDashBoard/getAllAppointments`, data);
    return response.data;
  };

  const adminDashboard = {
    getAdminDashboardCount,
    getAdminDashboardAllApt
  };
  
  export default adminDashboard;