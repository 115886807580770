import api from "../../../utils/api";

// create appointment details
const createAppointmentDetail = async (saveData) => {
  const response = await api.post(`/appointmentDetail/Create`, saveData);
  return response.data;
};

// update appointment details
const updateAppointmentDetail = async (saveData) => {
  const response = await api.put(`/appointmentDetail/Update`, saveData);
  return response.data;
};

// get appointment details
const getAppointmentDetail = async (data) => {
  const response = await api.get(`/appointmentDetail/get`, {
    params: data,
  });
  return response.data;
};

// create medical certificate
const createMedicalCertificate = async (saveData) => {
  const response = await api.post(`/MedicalCertificate/Create`, saveData);
  return response.data;
};

// update medical certificate
const updateMedicalCertificate = async (saveData) => {
  const response = await api.put(`/MedicalCertificate/Update`, saveData);
  return response.data;
};

// get medical certificate
const getMedicalCertificate = async (data) => {
  const response = await api.get(`/MedicalCertificate/get`, {
    params: data,
  });
  return response.data;
};

// delete medical certificate
const deleteMedicalCertificate = async (medicalcertificateId) => {
  const response = await api.delete(
    `/MedicalCertificate/delete?medicalcertificateId=${medicalcertificateId?.medicalcertificateId}`
  );
  return response.data;
};

const appointmentDetailsServices = {
  createAppointmentDetail,
  updateAppointmentDetail,
  getAppointmentDetail,
  createMedicalCertificate,
  updateMedicalCertificate,
  getMedicalCertificate,
  deleteMedicalCertificate,
};

export default appointmentDetailsServices;
