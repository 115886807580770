import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import patientNotes from "./patientNotes.service";
import { Notification } from "../../../components/GenericActions";

const initialState = {
  patientNotesData: null,
  allPatientNotesData: null,
  createPatientNotesData: null,
  updatePatientNotesData: null,
  deletePatientNotesData: null,

  isLoggedIn: false,
  isError: false,
  isSuccess: false,
  isLoading: false,
  statusMessage: "",
};

export const getPatientNotes = createAsyncThunk(
  "patientNotes/getPatientNotes",
  async (finalData, thunkAPI) => {
    try {
      return await patientNotes.getPatientNotes(finalData);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      // Notification(message, false);
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const getAllPatientNotes = createAsyncThunk(
  "patientNotes/getAllPatientNotes",
  async (finalData, thunkAPI) => {
    try {
      return await patientNotes.getAllPatientNotes(finalData);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      // Notification(message, false);
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const createPatientNotes = createAsyncThunk(
  "patientNotes/createPatientNotes",
  async ({ finalData, moveToNext }, thunkAPI) => {
    try {
      const response = await patientNotes.createPatientNotes(finalData);
      if (response.succeeded === true) {
        if (moveToNext) {
          moveToNext(response);
        }
      }
      Notification(response.message, response.succeeded);
      return response;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      if (error?.response?.status != 401) {
        Notification(message, false);
      }
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const updatePatientNotes = createAsyncThunk(
  "patientNotes/updatePatientNotes",
  async ({ finalData, moveToNext }, thunkAPI) => {
    try {
      const response = await patientNotes.updatePatientNotes(finalData);
      if (response.succeeded === true) {
        if (moveToNext) {
          moveToNext(response);
        }
      }
      Notification(response.message, response.succeeded);
      return response;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      if (error?.response?.status != 401) {
        Notification(message, false);
      }
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const deletePatientNotes = createAsyncThunk(
  "patientNotes/deletePatientNotes",
  async ({ finalData, moveToNext }, thunkAPI) => {
    try {
      const response = await patientNotes.deletePatientNotes(finalData);
      if (response.succeeded === true) {
        if (moveToNext) {
          moveToNext(response);
        }
      }
      Notification(response.message, response.succeeded);
      return response;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      if (error?.response?.status != 401) {
        Notification(message, false);
      }
      return thunkAPI.rejectWithValue(message);
    }
  }
);

const patientNotesSlice = createSlice({
  name: "patientNotes",
  initialState,
  reducers: {
    clearData: (state) => {
      state.isLoading = false;
      state.isSuccess = false;
      state.isError = false;
      state.statusMessage = "";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getPatientNotes.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getPatientNotes.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.patientNotesData = action.payload.data;
      })
      .addCase(getPatientNotes.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.statusMessage = action.payload;
      })

      .addCase(getAllPatientNotes.pending, (state) => {
        state.isLoading = true;
        state.message = "";
      })
      .addCase(getAllPatientNotes.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.allPatientNotesData = action.payload.data;
      })
      .addCase(getAllPatientNotes.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.statusMessage = action.payload;
      })

      .addCase(createPatientNotes.pending, (state) => {
        state.isLoading = true;
        state.message = "";
      })
      .addCase(createPatientNotes.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.createPatientNotesData = action.payload;
      })
      .addCase(createPatientNotes.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.statusMessage = action.payload;
      })
      .addCase(updatePatientNotes.pending, (state) => {
        state.isLoading = true;
        state.message = "";
      })
      .addCase(updatePatientNotes.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.updatePatientNotesData = action.payload;
      })
      .addCase(updatePatientNotes.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.statusMessage = action.payload;
      })
      .addCase(deletePatientNotes.pending, (state) => {
        state.isLoading = true;
        state.message = "";
      })
      .addCase(deletePatientNotes.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.deletePatientNotesData = action.payload;
      })
      .addCase(deletePatientNotes.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.statusMessage = action.payload;
      });
  },
});
export const { clearData } = patientNotesSlice.actions;

export default patientNotesSlice.reducer;
