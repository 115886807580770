import api from "../../../utils/api";

// create patient referral
const createPatientRefNotes = async (saveData) => {
  const response = await api.post(`/patientNote/create`, saveData);
  return response.data;
};

// delete patient referral
const deletePatientRefNotes = async (noteId) => {
  const response = await api.delete(
    `/patientNote/delete?noteId=${noteId?.noteId}`
  );
  return response.data;
};

const getRefNotes = async (data) => {
  const response = await api.get(`/patientNote/get`, {
    params: data,
  });
  return response.data;
};

const updatePatientRefNotes = async (data) => {
  const response = await api.put(`/patientNote/update`, data);
  return response.data;
};

const getAllRefNotes = async (saveData) => {
  const response = await api.post(`/patientNote/getAll`, saveData);
  return response.data;
};
const getPatientProfileNotes = async (data) => {
  const response = await api.get(`/patientNote/getAllPatientAppointmentNote`, {
    params: data,
  });
  return response.data;
};
const patientRefNotesServices = {
  createPatientRefNotes,
  deletePatientRefNotes,
  getRefNotes,
  updatePatientRefNotes,
  getAllRefNotes,
  getPatientProfileNotes
};

export default patientRefNotesServices;
