import { ScaleLoader } from "react-spinners";
import { css } from "@emotion/react";

const override = css`
  display: block;
  margin: auto;
`;
export default function FullScreenLoader({ fullHeight }) {
  return (
    <div
      className={`d-flex justify-content-center align-items-center ${
        fullHeight ? "h-100" : "loader-custom-h"
      }`}
    >
      <div className="spinner">
        <ScaleLoader
          color="#000071"
          // loading="true"
          // cssOverride={override}
          css={override}
          size={150}
          loading
        />
      </div>
    </div>
  );
}
