import api from "../../../utils/api";

const createPatientPharmacy = async (data) => {
  const response = await api.post(`/PatientPharmacy/create`, data);
  return response.data;
};

const getPatientPharmacy = async (data) => {
  const response = await api.get(`/PatientPharmacy/get`, {
    params: data,
  });
  return response.data;
};

const getAllPatientPharmacy = async (data) => {
  const response = await api.post(`/PatientPharmacy/getAll`, data);
  return response.data;
};

const updatePatienPharmacy = async (data) => {
  const response = await api.put(`/PatientPharmacy/update`, data);
  return response.data;
};

const deletePatienPharmacy = async (data) => {
  const response = await api.delete(`/PatientPharmacy/delete`, {
    params: data,
  });
  return response.data;
};

const sendAttachEmail = async (data) => {
  const response = await api.get(`/PatientPharmacy/sendAttachmentEmail`, {
    params: data
    });
  return response.data;
};

const patientPharmacyServices = {
  deletePatienPharmacy,
  createPatientPharmacy,
  getPatientPharmacy,
  getAllPatientPharmacy,
  updatePatienPharmacy,
  sendAttachEmail
};

export default patientPharmacyServices;
