import { initializeApp } from "firebase/app";
import { getMessaging, getToken, onMessage } from "firebase/messaging";
const firebaseConfig = {
  apiKey: "AIzaSyAgkltLnZ2w9R9-43jJb8zyCSUQ6wyVLbg",
  authDomain: "test-famdoc.firebaseapp.com",
  projectId: "test-famdoc",
  storageBucket: "test-famdoc.appspot.com",
  messagingSenderId: "549887321649",
  appId: "1:549887321649:web:e7631b0bfb6d51b9ccd710",
  measurementId: "G-X6QME8ZHTK",
};
export const app = initializeApp(firebaseConfig);
const messaging = getMessaging(app);

export const requestPermission = () => {
  return getToken(messaging, {vapidKey: 'BDLpnRtGxmakDksF1xdl5f8gLWzxjxfN3keOKNOOpuiky2M3fnRPqjdgkAtdxkNrBAb89JZxPmUTjeZnUCPENc4'}).then((currentToken) => {
    if (currentToken) {
      // Track the token -> client mapping, by sending to backend server
      // show on the UI that permission is secured
      return currentToken;
    } else {
      console.log('No registration token available. Request permission to generate one.');
      // shows on the UI that permission is required
      return false;
    }
  }).catch((err) => {
    console.log('An error occurred while retrieving token. ', err);
    // catch error while creating client token
    return false;
  });
};

export const onMessageListener = () =>
  new Promise((resolve) => {
    onMessage(messaging, (payload) => {
      resolve(payload);
    });
  });
