import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { Notification } from "../../../components/GenericActions";
import chat from "./chat.service";

const initialState = {
  allInboxList: [],
  isErrorAllInboxList: false,
  isSuccessAllInboxList: false,
  isLoadingAllInboxList: false,
  statusMessageAllInboxList: "",

  unreadMessageCount: null,
  inboxId: null,
  inboxCreate: null,
  allMessages: [],
  messageRead: null,
  messageSend: null,

  isError: false,
  isSuccess: false,
  isLoading: false,
  statusMessage: "",
};

export const getUnreadMessageCount = createAsyncThunk(
  "chat/getUnreadMessageCount",
  async (thunkAPI) => {
    try {
      return await chat.getUnreadMessageCount();
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      if (error?.response?.status != 401) {
        Notification(message, false);
      }
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const getInboxList = createAsyncThunk(
  "chat/getInboxList",
  async (finalData, thunkAPI) => {
    try {
      return await chat.getInboxList(finalData);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      Notification("Network Error", false);
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const getInboxId = createAsyncThunk(
  "chat/getInboxId",
  async (finalData, thunkAPI) => {
    try {
      return await chat.getInboxId(finalData);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      if (error?.response?.status != 401) {
        Notification(message, false);
      }
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const createInbox = createAsyncThunk(
  "chat/createInbox",
  async ({ finalData, movetoNext }, thunkAPI) => {
    try {
      const response = await chat.createInbox(finalData);
      if (response.succeeded === true) {
        if (movetoNext) {
          movetoNext(response);
        }
      }
      return response;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      if (error?.response?.status != 401) {
        Notification(message, false);
      }
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const getAllMessage = createAsyncThunk(
  "chat/getAllMessage",
  async (finalData, thunkAPI) => {
    try {
      return await chat.getAllMessage(finalData);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      Notification("Network Error", false);
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const messageIsRead = createAsyncThunk(
  "chat/messageIsRead",
  async (finalData, thunkAPI) => {
    try {
      return await chat.messageIsRead(finalData);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      if (error?.response?.status != 401) {
        Notification(message, false);
      }
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const sendMessage = createAsyncThunk(
  "chat/sendMessage",
  async ({ finalData, moveToNext }, thunkAPI) => {
    try {
      const response = await chat.sendMessage(finalData);
      if (response.succeeded === true) {
        if (moveToNext) {
          moveToNext(response);
        }
      }
      return response;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      if (error?.response?.status != 401) {
        Notification(message, false);
      }
      return thunkAPI.rejectWithValue(message);
    }
  }
);

const chatSlice = createSlice({
  name: "chat",
  initialState,
  reducers: {
    clearData: (state) => {
      state.isLoading = false;
      state.isSuccess = false;
      state.isError = false;
      state.statusMessage = "";
    },
    // pushSendMessade: (state, action) => {
    //   const pushUpdatedInbox = [...state.allInboxList];
    //   for (let i = 0; i < pushUpdatedInbox.length; i++) {
    //     if (pushUpdatedInbox[i].inboxId === action.payload.inboxId) {
    //       pushUpdatedInbox[i].unreadCount = 0;
    //       pushUpdatedInbox[i].message = action.payload.message;
    //       break;
    //     }
    //   }
    //   const pushUpdatedMessage = [...state.allMessages];
    //   pushUpdatedMessage.push(action.payload);
    //   state.allMessages = pushUpdatedMessage;
    //   state.allInboxList = pushUpdatedInbox;
    // },
    pushSendMessade: (state, action) => {
      const pushUpdatedInbox = state.allInboxList.map((inbox) => {
        if (inbox.inboxId === action.payload.inboxId) {
          return { ...inbox, unreadCount: 0, message: action.payload.message };
        }
        return inbox;
      });

      if (
        !state.allMessages.find(
          (msg) => msg.messageId === action.payload.messageId
        )
      ) {
        state.allMessages.push(action.payload);
      }

      state.allInboxList = pushUpdatedInbox;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getUnreadMessageCount.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getUnreadMessageCount.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.unreadMessageCount = action.payload;
      })
      .addCase(getUnreadMessageCount.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.statusMessage = action.payload;
      })
      .addCase(getInboxList.pending, (state) => {
        state.isLoadingAllInboxList = true;
      })
      .addCase(getInboxList.fulfilled, (state, action) => {
        state.isLoadingAllInboxList = false;
        state.isSuccessAllInboxList = true;
        state.allInboxList = action.payload.data;
      })
      .addCase(getInboxList.rejected, (state, action) => {
        state.isLoadingAllInboxList = false;
        state.isErrorAllInboxList = true;
        state.statusMessageAllInboxList = action.payload;
      })
      .addCase(getInboxId.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getInboxId.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.inboxId = action.payload.data;
      })
      .addCase(getInboxId.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.statusMessage = action.payload;
      })
      .addCase(createInbox.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(createInbox.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.inboxCreate = action.payload;
      })
      .addCase(createInbox.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.statusMessage = action.payload;
      })
      .addCase(getAllMessage.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getAllMessage.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.allMessages = action.payload.data;
      })
      .addCase(getAllMessage.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.statusMessage = action.payload;
      })
      .addCase(messageIsRead.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(messageIsRead.fulfilled, (state, action) => {
        const updateInbox = [...state.allInboxList];
        for (let i = 0; i < updateInbox.length; i++) {
          if (updateInbox[i].inboxId === action.meta.arg.inboxId) {
            updateInbox[i].unreadCount = 0;
            break;
          }
        }
        state.isLoading = false;
        state.isSuccess = true;
        state.allInboxList = updateInbox;
      })
      .addCase(messageIsRead.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.statusMessage = action.payload;
      })
      .addCase(sendMessage.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(sendMessage.fulfilled, (state, action) => {
        const upadteMessageList = [...state.allMessages];
        upadteMessageList.push(action.payload.data);

        const updatedInboxList = [...state.allInboxList];
        for (let i = 0; i < updatedInboxList.length; i++) {
          if (updatedInboxList[i].inboxId === action.payload.data.inboxId) {
            updatedInboxList[i].message = action.payload.data.message;
            break;
          }
        }
        state.isLoading = false;
        state.isSuccess = true;
        state.allMessages = upadteMessageList;
        state.allInboxList = updatedInboxList;
      })
      .addCase(sendMessage.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.statusMessage = action.payload;
      });
  },
});
export const { clearData, pushSendMessade } = chatSlice.actions;

export default chatSlice.reducer;
