import api from "../../../utils/api";

const createPatientPresForm = async (saveData) => {
  const response = await api.post(`/form/patientFormSave`, saveData);
  return response.data;
};

const getPatientPresForm = async (data) => {
  const response = await api.get(`/form/getPatientForm`, {
    params: data,
  });
  return response.data;
};

const getAllPatientPresForm = async (getData) => {
  const response = await api.get(`/form/getAllPatientForm`, {
    params: getData,
  });
  return response.data;
};

const formOneTimePayment = async (getData) => {
  const response = await api.post(`/strip/oneTimePayment`, getData);
  return response.data;
};

const getAllFormCount = async (getData) => {
  const response = await api.get(`/form/getAllFormCount`, getData);
  return response.data;
};

const patientOnlinePrescServices = {
  createPatientPresForm,
  getPatientPresForm,
  getAllPatientPresForm,
  formOneTimePayment,
  getAllFormCount
};

export default patientOnlinePrescServices;
