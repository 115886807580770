import api from "../../../utils/api";

const createFeeData = async (createUserRole) => {
  const response = await api.post(`/Fee/createFee`, createUserRole);
  return response.data;
};

const getFeeData = async (data) => {
  const response = await api.get(`/Fee/getFee`, {
    params: data,
  });
  return response.data;
};

const updateFeeData = async (data) => {
  const response = await api.put(`/Fee/updateFee`, data);
  return response.data;
};

const getAllFeeData = async (data) => {
  const response = await api.post(`/Fee/getAllFee`, data);
  return response.data;
};

const FeeService = {
  getAllFeeData,
  createFeeData,
  getFeeData,
  updateFeeData,
};

export default FeeService;
