import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { Notification } from "../../../components/GenericActions";
import patientPharmacyServices from "./patientPharmacy.service";

const initialState = {
  patientPharmacyData: null,
  allPatientPharmacyData: null,

  isLoggedIn: false,
  isError: false,
  isSuccess: false,
  isLoading: false,
  statusMessage: "",
};

export const getPatientPharmacyData = createAsyncThunk(
  "patient/getPharmacy",
  async (finalData, thunkAPI) => {
    try {
      return await patientPharmacyServices.getPatientPharmacy(finalData);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      // Notification(message, false);
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const getAllPatientPharmacy = createAsyncThunk(
  "patient/getAllPharmacy",
  async (finalData, thunkAPI) => {
    try {
      return await patientPharmacyServices.getAllPatientPharmacy(finalData);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      // Notification(message, false);
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const createPatientPharmacy = createAsyncThunk(
  "patient/createPharmacy",
  async ({ finalData, moveToNext, getPersonalInfo }, thunkAPI) => {
    try {
      const response = await patientPharmacyServices.createPatientPharmacy(
        finalData
      );
      if (response.succeeded === true) {
        if (moveToNext) {
          moveToNext(response);
        }
        if (getPersonalInfo) {
          getPersonalInfo(response);
        }
      }
      Notification(response.message, response.succeeded);
      return response;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      if (error?.response?.status != 401) {
        Notification(message, false);
      }
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const updatePatientPharmacy = createAsyncThunk(
  "patient/updatePharmacy",
  async ({ finalData, moveToNext, getPersonalInfo }, thunkAPI) => {
    try {
      const response = await patientPharmacyServices.updatePatienPharmacy(
        finalData
      );
      if (response.succeeded === true) {
        if (moveToNext) {
          moveToNext(response);
        }
        if (getPersonalInfo) {
          getPersonalInfo(response);
        }
      }
      Notification(response.message, response.succeeded);
      return response;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      if (error?.response?.status != 401) {
        Notification(message, false);
      }
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const deletePatientPharmacy = createAsyncThunk(
  "patient/deletePharmacy",
  async ({ finalData, onCreateSuccess }, thunkAPI) => {
    try {
      const response = await patientPharmacyServices.deletePatienPharmacy(
        finalData
      );
      if (response.succeeded === true) {
        if (onCreateSuccess) {
          onCreateSuccess();
        }
        Notification(response.message, response.succeeded);
      }
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      if (error?.response?.status != 401) {
        Notification(message, false);
      }
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const sendAttachEmail = createAsyncThunk(
  "email/prescritionPharmacy",
  async ({ finalData, appointmentStatus }, thunkAPI) => {
    try {
      const response = await patientPharmacyServices.sendAttachEmail(finalData);
      if (response.succeeded) {
        if (appointmentStatus) {
          appointmentStatus();
        }
        Notification(response.message, response.succeeded);
      } else {
        Notification(response.message, false);
      }
      return response;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      if (error?.response?.status != 401) {
        Notification(message, false);
      }
      return thunkAPI.rejectWithValue(message);
    }
  }
);

const patientPharmacySlice = createSlice({
  name: "patientPharmacy",
  initialState,
  reducers: {
    clearData: (state) => {
      state.isLoading = false;
      state.isSuccess = false;
      state.isError = false;
      state.statusMessage = "";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getPatientPharmacyData.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getPatientPharmacyData.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.patientPharmacyData = action.payload.data;
      })
      .addCase(getPatientPharmacyData.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.statusMessage = action.payload;
      })

      .addCase(getAllPatientPharmacy.pending, (state) => {
        state.isLoading = true;
        state.message = "";
      })
      .addCase(getAllPatientPharmacy.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.allPatientPharmacyData = action.payload.data;
      })
      .addCase(getAllPatientPharmacy.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.statusMessage = action.payload;
      })

      .addCase(createPatientPharmacy.pending, (state) => {
        state.isLoading = true;
        state.message = "";
      })
      .addCase(createPatientPharmacy.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.patientPharmacyData = action.payload;
      })
      .addCase(createPatientPharmacy.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.statusMessage = action.payload;
      })
      .addCase(updatePatientPharmacy.pending, (state) => {
        state.isLoading = true;
        state.message = "";
      })
      .addCase(updatePatientPharmacy.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.patientPharmacyData = action.payload;
      })
      .addCase(updatePatientPharmacy.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.statusMessage = action.payload;
      })
      .addCase(deletePatientPharmacy.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(deletePatientPharmacy.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.patientPharmacyData = action.payload;
      })
      .addCase(deletePatientPharmacy.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.statusMessage = action.payload;
      })
      .addCase(sendAttachEmail.pending, (state) => {
        state.isLoading = true;
        state.message = "";
      })
      .addCase(sendAttachEmail.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.patientPharmacyData = action.payload;
      })
      .addCase(sendAttachEmail.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.statusMessage = action.payload;
      });
  },
});
export const { clearData } = patientPharmacySlice.actions;

export default patientPharmacySlice.reducer;
